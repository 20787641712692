import { Stack, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import DoppelDefaultButton from '@/components/shared/doppel_default_button'
import { useIsEmployeeView } from '@/hooks/id_token_claims'
import BulkMoveAlertsModal from '@/components/alerts/bulk_move_alerts_modal'

type BulkMoveAlertContainerProps = {
  refreshFunc: () => void
}

export default function BulkMoveAlertsContainer({
  refreshFunc,
}: BulkMoveAlertContainerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isEmployeeView] = useIsEmployeeView()

  if (!isEmployeeView) {
    return <></>
  }

  return (
    <>
      <Stack direction="row" spacing={4}>
        <DoppelDefaultButton isInternal marginRight={4} onClick={onOpen}>
          Bulk Move Alerts
        </DoppelDefaultButton>

        <BulkMoveAlertsModal
          isOpen={isOpen}
          onClose={onClose}
          refreshFunc={refreshFunc}
        />
      </Stack>
    </>
  )
}
