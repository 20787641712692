import { Box, Flex, Spacer, Stack, TabList, Tabs } from '@chakra-ui/react'
import ReportsSearchBar from '@/components/collection_reports_table/reports_search_bar'
import RefreshButton from '@/components/tables/refresh_button'
import SpoofReportTab from '@/components/web2/spoof_report_tab'
import { SpoofReportsTable } from '@/components/web2/spoof_reports_table'
import SpoofReportsTableFilterBar from '@/components/web2/spoof_reports_table_filter_bar'
import BulkMoveAlertsContainer from '@/components/alerts/bulk_move_alerts_container'
import {
  DarkWebPlatforms,
  ReportFilterType,
  ReportStatus,
  ReportType,
} from '@/utils/constants'
import { defaultSortingMechanism } from '@/utils/reports/report_sorting_mechanism'
import {
  filterSpoofingReports,
  REPORT_TYPE_TO_PRODUCT_TYPE_MAP,
} from '@/utils/reports/report_utils'
import { calculateTotalPageCount } from '@/utils/table_utils'
import CustomerRubricLink from './internal/customer_rubric_link'
import PlatformTabs from '@/components/web2/spoof_report_platform_tabs'
import UnifiedReportsTableFilters from './admin/unified_reports_table_filters'
import UploadNewReportContainer from './web2/upload_new_report_container'
import UploadNewBulkReportContainer from './web2/upload_new_bulk_report_container'
import BulkAssignAlertsContainer from './internal/bulk_assign_alerts_container'
import RequestCSVExportButton from './csv/request_csv_export_button'
import { useIsUserAuthorizedForAction } from '@/utils/permissions'
import { Permission } from '@/generated/enums'
import { useAlertsTable } from '@/hooks/alerts_table'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { userProductsAtom } from '@/atoms/permissions'
import {
  useReportStatuses,
  useSearchKey,
  useSelectedAutocompleteReportId,
  useSelectedReportFilters,
  useSelectedReportStatus,
  useSelectedReportType,
  useSelectedSortingMechanism,
} from '@/pages/reports'
import { useRouter } from 'next/router'
import {
  getOrgData,
  useIsEmployeeView,
  useOrgID,
  useUserID,
} from '@/hooks/id_token_claims'
import {
  useSelectedPage,
  useSelectedPageSize,
  useSelectedSpoofReport,
} from '@/hooks/spoof_report_table_filters'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'
import { alertsTableResultAtom } from '@/utils/detailed_view/alerts_table_context'
import AssignNextAlertButton from './web2/alert_detail_view/sections/assign_next_alert_button'

type ReportsPageProps = {
  isOrgUnifiedView: boolean
}

const ReportsPage = ({ isOrgUnifiedView }: ReportsPageProps) => {
  const filterImprovementsEnabled = useFeatureFlagEnabled('filter-improvements')
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()
  const router = useRouter()
  const orgId = useOrgID()
  const [orgData] = getOrgData()
  const [userId] = useUserID()
  const [selectedPage, setSelectedPage] = useSelectedPage()
  const [selectedPageSize] = useSelectedPageSize()
  const [selectedSpoofReport, setSelectedSpoofReport] = useSelectedSpoofReport()
  const [reportStatuses, setReportStatuses] = useReportStatuses()
  const [selectedReportStatus, setSelectedReportStatus] = useSelectedReportStatus()
  const [selectedReportType] = useSelectedReportType()
  const [selectedSortingMechanism, setSelectedSortingMechanism] =
    useSelectedSortingMechanism()
  const [isEmployeeView] = useIsEmployeeView()
  const [searchKey] = useSearchKey()
  const [selectedAutocompleteReportId] = useSelectedAutocompleteReportId()
  const [selectedReportExposureType, setSelectedReportExposureType] =
    useAtom(reportPlatformTabAtom)
  const setAlertsTableResult = useSetAtom(alertsTableResultAtom)
  const isUserAuthorizedForCsvExport = useIsUserAuthorizedForAction(
    Permission.ALERT_CSV_READ,
  )
  const userProducts = useAtomValue(userProductsAtom)
  const isRbacEnabled = useFeatureFlagEnabled('rbac')

  const {
    refreshTable,
    spoofReportsWhere,
    spoofingReportCounts,
    autocompleteClickCallback,
    createSpoofingReportsWhereClause,
    searchSpoofingReportsData,
    searchSpoofingReportsLoading,
    searchSpoofingReportsNetworkStatus,
    setToRefreshCache,
  } = useAlertsTable(
    isOrgUnifiedView,
    selectedReportFilters,
    router,
    orgId,
    orgData,
    userId,
    selectedPage,
    setSelectedPage,
    selectedSpoofReport,
    setSelectedSpoofReport,
    setReportStatuses,
    selectedReportStatus,
    selectedSortingMechanism,
    isEmployeeView,
    searchKey,
    selectedAutocompleteReportId,
    selectedReportExposureType,
    setAlertsTableResult,
    selectedReportType,
    selectedPageSize,
    userProducts,
    isRbacEnabled,
  )

  const UploadAlertButtons = () => {
    const isUserAuthorizedForAlertUpload = useIsUserAuthorizedForAction(
      Permission.ALERT_CREATE,
    )

    if (!isUserAuthorizedForAlertUpload) return null

    return (
      <>
        <UploadNewReportContainer refreshFunc={refreshTable} />

        <UploadNewBulkReportContainer refreshFunc={refreshTable} />
      </>
    )
  }

  return (
    <>
      <Flex direction="column" width="100%">
        {selectedReportType === ReportType.DARK_WEB && (
          <Tabs
            colorScheme="doppel"
            index={Object.values(DarkWebPlatforms).indexOf(selectedReportExposureType)}
            marginBottom="40px"
            onChange={(index) => {
              if (index < Object.values(DarkWebPlatforms).length) {
                const newSelectedExposureType = Object.values(DarkWebPlatforms)[index]
                setSelectedReportExposureType(newSelectedExposureType)
                setSelectedPage(1)
              }
            }}
            size="sm"
          >
            <TabList>
              {Object.values(DarkWebPlatforms).map((exposureType) => (
                <div key={exposureType}>
                  <PlatformTabs
                    reportExposureType={exposureType}
                    spoofingReportsWhereClause={spoofReportsWhere}
                  />
                </div>
              ))}
            </TabList>
          </Tabs>
        )}

        <Stack marginBottom={6} spacing={3}>
          <Flex alignItems="center" direction="row">
            {!filterImprovementsEnabled && (
              <ReportsSearchBar
                autocompleteClickCallback={autocompleteClickCallback}
                isOrgUnifiedView={isOrgUnifiedView}
              />
            )}

            {!filterImprovementsEnabled && <RefreshButton refreshFunc={refreshTable} />}

            <Spacer />

            {isOrgUnifiedView && <UnifiedReportsTableFilters />}

            {!isOrgUnifiedView && <CustomerRubricLink />}

            {!filterImprovementsEnabled && (
              <BulkAssignAlertsContainer refreshFunc={refreshTable} />
            )}

            {!filterImprovementsEnabled && (
              <BulkMoveAlertsContainer refreshFunc={refreshTable} />
            )}

            {!filterImprovementsEnabled && isUserAuthorizedForCsvExport && (
              <RequestCSVExportButton
                isOrgUnifiedView={isOrgUnifiedView}
                makeWhereClause={createSpoofingReportsWhereClause(isOrgUnifiedView)}
              />
            )}

            {!isOrgUnifiedView && !filterImprovementsEnabled && <UploadAlertButtons />}
          </Flex>

          <Flex direction="row" gap="10px">
            <AssignNextAlertButton
              product={REPORT_TYPE_TO_PRODUCT_TYPE_MAP[selectedReportType]}
            />

            <SpoofReportsTableFilterBar
              isOrgUnifiedView={isOrgUnifiedView}
              selectedOrgIds={selectedReportFilters
                .filter((filter) => filter.filterType === ReportFilterType.Organization)
                .map((filter) => filter.value)}
            />
          </Flex>
        </Stack>

        <Tabs
          colorScheme="doppel"
          index={reportStatuses.indexOf(
            selectedReportStatus || ReportStatus.ENUM_NEEDS_REVIEW,
          )}
          onChange={(index) => {
            if (index < reportStatuses.length) {
              const newStatusType = reportStatuses[index]
              setSelectedReportStatus(newStatusType)
              setSelectedSortingMechanism(
                defaultSortingMechanism(
                  newStatusType,
                  selectedReportType,
                  isEmployeeView,
                ),
              )
              setSelectedPage(1)
            }
          }}
          size="sm"
        >
          <TabList>
            {reportStatuses.map((status) => (
              <div key={status}>
                <SpoofReportTab
                  reportStatus={status}
                  spoofingReportsWhereClause={spoofReportsWhere}
                />
              </div>
            ))}

            <Spacer />
          </TabList>
        </Tabs>

        <Box left="0px" overflowX="auto" overflowY="auto">
          <SpoofReportsTable
            data={filterSpoofingReports(
              searchSpoofingReportsData,
              selectedReportStatus,
            )}
            isOrgUnifiedView={isOrgUnifiedView}
            loading={searchSpoofingReportsLoading && !searchSpoofingReportsData}
            setToRefreshCache={setToRefreshCache}
            totalPageCount={calculateTotalPageCount(
              spoofingReportCounts,
              selectedPageSize,
            )}
          />
        </Box>
      </Flex>
    </>
  )
}

export default ReportsPage
