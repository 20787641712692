import Layout from '@/components/layout'
import Reports from './reports'
import ReportsPage from '@/components/reports_page'

const Web2 = () => {
  return <ReportsPage isOrgUnifiedView={false} />
}

Web2.getLayout = function getLayout(page) {
  return (
    <Layout>
      <Reports component={page}></Reports>
    </Layout>
  )
}

export default Web2
